.MyAvatar {
  align-self: flex-end;
  font-size: medium;
}

.User {
  color: rgb(192, 192, 64);
}

.Logout {
  color: rgb(192, 64, 64);
}