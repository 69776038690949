@import '~antd/dist/antd.css';

.App {
  width: 1440px;
  height: 1825px;
  position: relative;
  background-color: white;
  overflow: clip;
  font-family: Noto Sans TC;
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  /* height: 48px; */
}

.Carousel {
  height: 450px;
  left: 0px;
  /* top: 106px; */
  right: 0px;
  position: relative;
}

.BulletinFrame {
  width: 1440px;
  box-sizing: border-box;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 70px;
  padding-right: 70px;
  position: absolute;
  left: 0px;
  top: 556px;
  right: 0px;
  gap: -12px;
  display: inline-flex;
  align-items: center;
  color: white;
  text-align: left;
  font-weight: 400;
}

.Bulletin {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  gap: 10px;

  width: 1141px;
  height: 40px;

  /* 13815E */

  background: #13815E;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.Footer {
  /* footer */


  position: relative;
  width: 1440px;
  height: 414px;

  /* 4E9467 */

  background: #4E9467;
}

.FooterLogo {
  position: absolute;
  width: 140px;
  height: 56px;
  left: 120px;
  top: 268px;
}

.PlatformLogo {
  width: 171px;
  height: 72px;
  background-blend-mode: screen;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.EighteenPlusLogo {
  position: absolute;
  width: 52px;
  height: 52px;
  left: 1266px;
  top: 270px;

  /* FFFFFF */
/* 
  background: #FFFFFF; */
}

.FooterComment {
  font-family: 'Noto Sans TC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;

  /* FFFFFF */

  color: #FFFFFF;
}